import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../components/AppNavbar';

const ContactEdit = () => {
  const initialFormState = {
    id: 0,
    name: ''
  };
  const [contact, setContact] = useState(initialFormState);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id !== 'new') {
      fetch(`/bff/api/contacts/${id}`)
        .then(response => response.json())
        .then(data => setContact(data));
    }
  }, [id, setContact]);

  const handleChange = (event) => {
    const { name, value } = event.target

    setContact({ ...contact, [name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    await fetch(`/bff/api/contacts${contact.id ? `/${contact.id}` : ''}`, {
      method: (contact.id) ? 'PUT' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contact)
    });
    setContact(initialFormState);
    navigate('/');
  }

  const title = <h2>{contact.id ? 'Edit Contact' : 'Add Contact'}</h2>;

  return (<div>
      <AppNavbar/>
      <Container>
        {title}
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" name="name" id="name" value={contact.name || ''}
                   onChange={handleChange} autoComplete="name"/>
          </FormGroup>
          <FormGroup>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" tag={Link} to="/">Cancel</Button>
          </FormGroup>
        </Form>
      </Container>
    </div>
  )
};

export default ContactEdit;
