import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import { Link } from 'react-router-dom';

const ContactList = () => {
	const [contacts, setContacts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	
	useEffect(() => {
		setLoading(true);

		checkAuth()
			.then((data) => {
				setIsAuthenticated(true);

				fetch('/bff/api/contacts')
					.then((response) => response.json())
					.catch((error) => {
						console.error(error);
					})
					.then((data) => {
						setContacts(data);
					});
			})
			.catch((error) => {
				setIsAuthenticated(false);
			});

		setLoading(false);
	}, []);

	const remove = async (id) => {
		await fetch(`/bff/api/contacts/${id}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).catch((error) => {
			console.log(error);
		}).then(() => {
			let updatedContacts = [...contacts].filter(i => i.id !== id);
			setContacts(updatedContacts);
		});
	}

	const callLogin = () => {
		window.location.href = '/oauth2/authorization/contacts-client';
		//window.location.href = 'https://accounts.corymiller.dev/oauth2/authorization/contacts-client';
	}

	const checkAuth = async () => {
		const resp = await fetch('/bff/api/me');
		return await resp.json();
	}

	if (loading) {
		return (
			<div>
				<AppNavbar/>
				<Container fluid>
					<p>Loading...</p>
				</Container>
			</div>
		);
	}

	const contactList = contacts?.map(contact => {
		return (
			<tr key={contact.id}>
				<td style={{whiteSpace: 'nowrap'}}>{contact.name}</td>
				<td>
        				<ButtonGroup>
          					<Button size="sm" color="primary" tag={Link} to={"/c/" + contact.id}>Edit</Button>
						&nbsp;
          					<Button size="sm" color="danger" onClick={() => remove(contact.id)}>Delete</Button>
        				</ButtonGroup>
      				</td>
			</tr>
		);
	});

	return (
		<div>
			<AppNavbar/>
			{!isAuthenticated ? (
			<Container>
				<div>
					<Button onClick={callLogin}>Login</Button>
				</div>
			</Container>
			) : (
			<Container fluid>
				<div className="float-end">
					<Button color="success" tag={Link} to="/c/new">Add Contact</Button>
				</div>
				<h3>Test</h3>
				<Table className="mt-4">
					<thead>
					<tr>
						<th style={{width: '20%'}}>Name</th>
						<th style={{width: '10%'}}>Actions</th>
					</tr>
					</thead>
					<tbody>
					{contacts !== undefined ? contactList : <tr></tr>}
					</tbody>
				</Table>
			</Container>
			)}
		</div>
	);
};

export default ContactList;
