import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactList from './views/ContactList';
import ContactEdit from './views/ContactEdit';

function App() {
  return (
	<Router>
      		<Routes>
        		<Route path="/" element={<ContactList/>}/>
        		<Route path='/c/:id' element={<ContactEdit/>}/>
      		</Routes>
    	</Router>
  );
}

export default App;
